.preview-block {
  width: 100%;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);

  padding: 16px;
  gap: 16px;

  border-radius: 12px;
  border: 1px solid #e7e7e7;

  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
  .details-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    .details {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      .qno {
        height: 44px;
        width: 44px;
        background-color: #327091;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        border-radius: 4px;
      }
      .label-container {
        flex: 1;
        min-width: 0px;
        .label-wrapper {
          margin-top: 4px;
          display: flex;
          &.required {
            &::after {
              content: "*";
              color: red;
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
  .component-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 85%;
    padding-left: 65px;
    .image-container {
      max-height: 97px;
      max-width: 192px;
      border-radius: 8px;
      cursor: pointer;
      .preview-image {
        max-height: 97px;
        max-width: 192px;
        object-fit: contain;
        border-radius: 8px;
      }
    }
  }
  .settings-block {
    display: flex;
    align-items: center;
  }
}

.preview-wrapper {
  .radio-preview {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    .radio-preview-item {
      display: flex;
      align-items: center;
    }
    &.vertical {
      flex-direction: column;
      align-items: flex-start;
    }
    .radio-image-preview {
      max-height: 50px;
      max-width: 192px;
      border-radius: 8px;
      cursor: pointer;
      margin-left: 8px;
    }
  }
  .dropdown-preview {
    display: flex;
    flex-direction: column;
    width: 350px;
  }
  .simple-preview-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .slider-preview-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: space-between;
    }
  }
}
